exports.components = {
  "component---src-page-templates-404-tsx": () => import("./../../../src/page-templates/404.tsx" /* webpackChunkName: "component---src-page-templates-404-tsx" */),
  "component---src-page-templates-about-us-contacts-tsx": () => import("./../../../src/page-templates/about-us/contacts.tsx" /* webpackChunkName: "component---src-page-templates-about-us-contacts-tsx" */),
  "component---src-page-templates-about-us-culture-tsx": () => import("./../../../src/page-templates/about-us/culture.tsx" /* webpackChunkName: "component---src-page-templates-about-us-culture-tsx" */),
  "component---src-page-templates-about-us-index-tsx": () => import("./../../../src/page-templates/about-us/index.tsx" /* webpackChunkName: "component---src-page-templates-about-us-index-tsx" */),
  "component---src-page-templates-article-index-tsx": () => import("./../../../src/page-templates/article/index.tsx" /* webpackChunkName: "component---src-page-templates-article-index-tsx" */),
  "component---src-page-templates-blog-tsx": () => import("./../../../src/page-templates/blog.tsx" /* webpackChunkName: "component---src-page-templates-blog-tsx" */),
  "component---src-page-templates-career-tsx": () => import("./../../../src/page-templates/career.tsx" /* webpackChunkName: "component---src-page-templates-career-tsx" */),
  "component---src-page-templates-case-study-index-tsx": () => import("./../../../src/page-templates/case-study/index.tsx" /* webpackChunkName: "component---src-page-templates-case-study-index-tsx" */),
  "component---src-page-templates-clients-tsx": () => import("./../../../src/page-templates/clients.tsx" /* webpackChunkName: "component---src-page-templates-clients-tsx" */),
  "component---src-page-templates-index-tsx": () => import("./../../../src/page-templates/index.tsx" /* webpackChunkName: "component---src-page-templates-index-tsx" */),
  "component---src-page-templates-podcasts-tsx": () => import("./../../../src/page-templates/podcasts.tsx" /* webpackChunkName: "component---src-page-templates-podcasts-tsx" */),
  "component---src-page-templates-services-software-development-custom-software-tsx": () => import("./../../../src/page-templates/services/software-development/custom-software.tsx" /* webpackChunkName: "component---src-page-templates-services-software-development-custom-software-tsx" */),
  "component---src-page-templates-services-software-development-mobile-development-tsx": () => import("./../../../src/page-templates/services/software-development/mobile-development.tsx" /* webpackChunkName: "component---src-page-templates-services-software-development-mobile-development-tsx" */),
  "component---src-page-templates-services-software-development-web-development-tsx": () => import("./../../../src/page-templates/services/software-development/web-development.tsx" /* webpackChunkName: "component---src-page-templates-services-software-development-web-development-tsx" */),
  "component---src-page-templates-services-technological-solutions-digital-strategy-tsx": () => import("./../../../src/page-templates/services/technological-solutions/digital-strategy.tsx" /* webpackChunkName: "component---src-page-templates-services-technological-solutions-digital-strategy-tsx" */),
  "component---src-page-templates-services-technological-solutions-ui-ux-tsx": () => import("./../../../src/page-templates/services/technological-solutions/ui-ux.tsx" /* webpackChunkName: "component---src-page-templates-services-technological-solutions-ui-ux-tsx" */)
}

